import "./App.css";
import React from 'react';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import axios from 'axios';
import abi from './abi';
import pancakeSwapRouterABI from './pancakeSwapRouterABI.json';
import ERC20ABI from './ERC20ABI.json';

function App() {

  const [currentAccount, setCurrentAccount] = useState(null);
  const [bnbPrice, setBnbPrice] = useState(null);
  const [productName, setProductName] = useState(null);
  const [productDescription, setProductDescription] = useState(null);
  const [productUrl, setProductUrl] = useState(null);
  const [productPrice, setProductPrice] = useState(0);
  const [productLogo, setProductLogo] = useState(null);

  const [ownerAddress, setOwnerAddress] = useState(null);
  const [tokenAddress, setTokenAddress] = useState(null);
  const [tokenName, setTokenName] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(null);
  const [tokenEnabled, setTokenEnabled] = useState(null);

  const queryParams = new URLSearchParams(window.location.search);
  const productID = queryParams.get('id') === null ? 1 : queryParams.get('id');

  const contractAddress = '0xEC19d08673c63a70857418b4F82184AD460C5991';
  const fee = '500000000000000';
  const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
  const routerAddress = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
  const bnbAddress = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c';
  const usdtAddress = '0x55d398326f99059fF775485246999027B3197955';
  const usdxAddress = '0xB62D20f527490D78837c8656f6a28331D7723b34';
  const usdcAddress = '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d';


  const fetchConfig = async () => {
    try {
      const response = await axios.get('config.json');
      setOwnerAddress(response.data[0].OwnerWallet);
      setTokenName(response.data[0].TokenName);
      setTokenSymbol(response.data[0].TokenSymbol);
      setTokenAddress(response.data[0].TokenContract);
      setTokenPrice(response.data[0].TokenPrice);
      const boolValue = response.data[0].TokenEnabled === "true" ? true : false;
      setTokenEnabled(boolValue);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getBnbPrice = async () => {
    // Call the getAmountsOut function to get the price of your token in BNB
    const router = new ethers.Contract(routerAddress, pancakeSwapRouterABI, provider);
    router.getAmountsOut(ethers.utils.parseUnits('1', 18), [bnbAddress, usdtAddress])
    .then((result) => {
      const bnbPriceInUSDT = ethers.utils.formatUnits(result[1], 18);
      setBnbPrice(bnbPriceInUSDT);
    })
    .catch((error) => {
      console.error('Error fetching token price:', error);
    });
  }

  useEffect(() => {

    checkWalletIsConnected();

    const fetchProducts = async () => {
      try {
        const response = await axios.get('products.json');
        setProductName(response.data[productID - 1].product);
        setProductDescription(response.data[productID - 1].description);
        setProductUrl(response.data[productID - 1].url);
        setProductPrice(response.data[productID - 1].price);
        setProductLogo(response.data[productID - 1].logo)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchConfig();
    fetchProducts();
    getBnbPrice();

  }, [productID]);

  const checkWalletIsConnected = async () => {
    const detectedProvider = await detectEthereumProvider();
    if(!detectedProvider){
      console.log("Metamask NOT Installed");
      return;
    }else{
      console.log("Metamask Installed");
    }
   }

  const connectWalletHandler = async() => { 
    const detectedProvider = await detectEthereumProvider();
    if(!detectedProvider){
      alert("Please Install Metamask!");
    }

    try{
      const accounts = await detectedProvider.request({method: 'eth_requestAccounts'});
      console.log("Found an account :", accounts[0]);
      setCurrentAccount(accounts[0]);
    }catch (err){
      console.log(err);
    }
  }

  const prePayment = async () => {
    // Check Wallet Connected
    if (currentAccount === null){
      connectWalletHandler();
      return false;
    }

    // Check Chain ID
    const detectedProvider = await detectEthereumProvider();
    const chainId = await detectedProvider.request({ method: 'eth_chainId' });
    if(parseInt(chainId, 16) !== 56){
      alert("Please Switch MetaMask to BNB Smart Chain");
      await detectedProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }],
      });
      return false;
    }

    return true;
  }
 
  // Pay Buttons
  const bnbClick = async () => {
    prePayment();
    if (currentAccount === null){
      return;
    }
    const bnbAmount = ((Number(productPrice) / Number(bnbPrice)) + 0.0005).toFixed(5);
    // alert(bnbAmount);
    try{
      const detectedProvider = await detectEthereumProvider();

      if(detectedProvider){
        const provider = new ethers.providers.Web3Provider(detectedProvider);
        const signer = provider.getSigner();
        console.log(contractAddress);
        const contract = new ethers.Contract(contractAddress, abi, signer);

        // Get BNB balance
        console.log(currentAccount);
        const balance = await provider.getBalance(currentAccount);
        console.log(balance);
        if(Number(balance) < ethers.utils.parseUnits(bnbAmount, 18)){
          alert('Not enough BNB balance in your wallet');
          return;
        };

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payBnb(fee, ownerAddress, {value: ethers.utils.parseUnits(bnbAmount, 18)});

        if(getadrp){
          window.location.href = productUrl;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const usdtClick = async () => {
    prePayment();
    if (currentAccount === null){
      return;
    }
    try{
      const detectedProvider = await detectEthereumProvider();

      if(detectedProvider){
        const provider = new ethers.providers.Web3Provider(detectedProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);
        const usdtContractApproval = new ethers.Contract(usdtAddress, ERC20ABI, signer);

        // Get the USDT balance
        const usdtBal = new ethers.Contract(usdtAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(currentAccount);
        if(ethers.utils.formatUnits(balance, 18) < parseInt(productPrice,10)){
          alert('Not enough USDT balance in your wallet');
          return;
        };

        // Approve USDT Transaction
        const approvalTx = await usdtContractApproval.approve(contractAddress, ethers.utils.parseUnits(productPrice, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payUSDT(ethers.utils.parseUnits(productPrice, 18), ownerAddress, {value: fee});

        if(getadrp){
          window.location.href = productUrl;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const usdxClick = async () => {
    prePayment();
    if (currentAccount === null){
      return;
    }
    try{
      const detectedProvider = await detectEthereumProvider();

      if(detectedProvider){
        const provider = new ethers.providers.Web3Provider(detectedProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);
        const usdxContractApproval = new ethers.Contract(usdxAddress, ERC20ABI, signer);

        // Get the USDX balance
        const usdtBal = new ethers.Contract(usdxAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(currentAccount);
        if(ethers.utils.formatUnits(balance, 18) < parseInt(productPrice,10)){
          alert('Not enough USDX balance in your wallet');
          return;
        };

        // Approve USDX Transaction
        const approvalTx = await usdxContractApproval.approve(contractAddress, ethers.utils.parseUnits(productPrice, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payUSDX(ethers.utils.parseUnits(productPrice, 18), ownerAddress, {value: fee});

        if(getadrp){
          window.location.href = productUrl;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const usdcClick = async () => {
    prePayment();
    if (currentAccount === null){
      return;
    }
    try{
      const detectedProvider = await detectEthereumProvider();

      if(detectedProvider){
        const provider = new ethers.providers.Web3Provider(detectedProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);
        const usdcContractApproval = new ethers.Contract(usdcAddress, ERC20ABI, signer);

        // Get the USDC balance
        const usdtBal = new ethers.Contract(usdcAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(currentAccount);
        if(ethers.utils.formatUnits(balance, 18) < parseInt(productPrice,10)){
          alert('Not enough USDC balance in your wallet');
          return;
        };

        // Approve USDC Transaction
        const approvalTx = await usdcContractApproval.approve(contractAddress, ethers.utils.parseUnits(productPrice, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payUSDC(ethers.utils.parseUnits(productPrice, 18), ownerAddress, {value: fee});

        if(getadrp){
          window.location.href = productUrl;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const tokenClick = async () => {
    prePayment();
    if (currentAccount === null){
      return;
    }
    try{
      const detectedProvider = await detectEthereumProvider();
      const finalPayAmount = (productPrice / Number(tokenPrice)).toFixed(2);

      if(detectedProvider){
        const provider = new ethers.providers.Web3Provider(detectedProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);
        const tokenContractApproval = new ethers.Contract(tokenAddress, ERC20ABI, signer);

        // Get the Token balance
        const usdtBal = new ethers.Contract(tokenAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(currentAccount);
        if(ethers.utils.formatUnits(balance, 18) < parseInt(finalPayAmount,10)){
          alert('Not enough token balance in your wallet');
          return;
        };

        // Approve Token Transaction
        const approvalTx = await tokenContractApproval.approve(contractAddress, ethers.utils.parseUnits(finalPayAmount, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payToken(ethers.utils.parseUnits(finalPayAmount, 18), ownerAddress, tokenAddress, {value: fee});

        if(getadrp){
          window.location.href = productUrl;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }


  return (
    <div className="div-self">
      <div className="card-s">
        <div className="card-header-s">
          <div className="flex-logo">
            <div className="logo-div">
              <img
                src={productLogo}
                className="company-logo"
                alt="Company Logo"
              />
            </div>
          </div>
          <div className="d-flex-auto">
            <span className="header-text-s">{productName}</span>
          </div>
        </div>

        <div>
          <br />
          <div className="contract-div">
            <p>
              {productDescription}
            </p>
          </div>
          <h6 className="claim-h">Pay ( ${productPrice} )</h6>
          {tokenEnabled &&
          <>
          <button className="btn-claim" tabindex="0" type="button" onClick={tokenClick}>
            <span className="span-d">
              <img
                src="./coins/token.png"
                width={40}
                height={40}
                alt="Token Logo"
              />
            </span>
            ZXF
          </button>
          <br />
          <br />
          </>
          }
          <button className="btn-claim" tabindex="0" type="button" onClick={bnbClick}>
            <span className="span-d">
              <img
                src="./coins/bnb.png"
                width={40}
                height={40}
                alt="BNB Logo"
              />
            </span>
            BNB 
          </button>

          <br />
          <br />
          <button className="btn-claim" tabindex="0" type="button" onClick={usdtClick}>
            <span className="span-d">
              <img
                src="./coins/usdt.png"
                width={40}
                height={40}
                alt="USDT Logo"
              />
            </span>
            USDT
          </button>
          <br />
          <br />
          <button className="btn-claim" tabindex="0" type="button" onClick={usdxClick}>
            <span className="span-d">
              <img
                src="./coins/usdx.png"
                width={40}
                height={40}
                alt="USDT Logo"
              />
            </span>
            USDX
          </button>
          <br />
          <br />
          <button className="btn-claim" tabindex="0" type="button" onClick={usdcClick}>
            <span className="span-d">
              <img
                src="./coins/usdc.png"
                width={40}
                height={40}
                alt="USDC Logo"
              />
            </span>
            USDC
          </button>

        </div> 

      </div>
    </div>
  );
}

export default App;
